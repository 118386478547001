<template>
  <div class="qrPage">
    <NavBar />
    <v-container fluid>
      <h1>Управление Qr-кодами</h1>
      <v-row class="my-2">
        <v-col>
          <v-btn @click="createQrDialog = true" color="red" class="white--text"> <v-icon left>mdi-plus</v-icon>Добавить </v-btn>
        </v-col>
      </v-row>
      <!-- <v-card> -->
      <v-data-table :headers="headers" :items="qrList" no-data-text="Нет данных" disable-pagination hide-default-footer>
        <template v-slot:item.actionCopy="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="copyUrlClick(item)" v-on="on" v-bind="attrs" color="red" text><v-icon>mdi-content-copy</v-icon></v-btn>
            </template>
            <span>Копировать</span>
          </v-tooltip>
        </template>
        <template v-slot:item.actionDelete="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="deleteQRClick(item)" v-on="on" v-bind="attrs" color="red" text>
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <span>Удалить</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-dialog v-model="createQrDialog" max-width="600">
        <v-card>
          <v-card-title>Создание QR-кода</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-autocomplete
                  label="Выберите вокзал"
                  outlined
                  v-model="newQrData.stationToken"
                  @change="clearTakeoutAndCafe()"
                  :items="getStation()"
                ></v-autocomplete>
                <v-autocomplete
                  label="Выберите точку выдачи"
                  outlined
                  v-model="newQrData.takeoutToken"
                  @change="clearCafe()"
                  :items="getTakeout()"
                ></v-autocomplete>
                <v-autocomplete label="Выберите кафе" outlined v-model="newQrData.cafeToken" :items="getCafe()"></v-autocomplete> </v-col
            ></v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red" @click="addNewQrClick" text>Создать</v-btn>
            <v-btn color="red" @click="createQrDialog = false" text>Закрыть</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- </v-card> -->
    </v-container>
    <div class="notification">
      <Notification />
    </div>
  </div>
</template>
<script>
import NavBar from '@/components/NavBar';
import config from '@/config/config';
import { mapActions, mapGetters } from 'vuex';
import Notification from '@/components/Notification';

export default {
  data() {
    return {
      qrList: [],
      tooltipOver: false,
      confirmDelete: {},
      createQrDialog: false,
      newQrData: {
        stationToken: null,
        cafeToken: null,
        takeoutToken: null
      }
    };
  },
  components: {
    NavBar,
    Notification
  },
  computed: {
    headers() {
      return [
        {
          text: '',
          value: 'actionCopy',
          sortable: false
        },
        {
          text: 'Станция',
          value: 'stationName'
        },
        {
          text: 'Точка выдачи',
          value: 'takeoutName'
        },
        {
          text: 'Кафе',
          value: 'cafeName'
        },
        {
          text: 'Ссылка',
          value: 'link',
          sortable: false
        },
        {
          text: '',
          value: 'actionDelete',
          sortable: false
        }
      ];
    },
    qrs() {
      let qrArray = [];
      if (this.qrListResult) {
        this.qrListData.forEach((qr) => {
          qrArray.push(qr);
        });
      }
      return qrArray;
    },
    stations() {
      let stationsArr = [];
      if (this.stationsListResult) {
        this.stationsList.forEach((station) => stationsArr.push(station));
      }
      return stationsArr;
    },
    cafes() {
      let cafeArr = [];
      if (this.cafeListResult) {
        this.cafeList.forEach((cafe) => cafeArr.push(cafe));
      }
      return cafeArr;
    },
    takeouts() {
      let takeoutsArr = [];
      if (this.takeoutsResult) {
        this.takeoutsData.forEach((takeout) => takeoutsArr.push(takeout));
      }
      return takeoutsArr;
    },
    createQRData() {
      let dataArr = [];
      if (this.qrListResult) {
        this.dataForCreateQr.forEach((data) => dataArr.push(data));
      }
      return dataArr;
    },
    qrObj() {
      let qrArray = [];
      if (this.stations.length > 0 && this.cafes.length > 0 && this.takeouts.length > 0 && this.qrs.length > 0) {
        this.qrs.forEach((qr) => {
          let stationInfo, cafeName, takeoutName;
          if (qr.station !== null) {
            stationInfo = this.stations.find((station) => station.token === qr.station.token);
          }
          if (qr.cafe !== null) {
            cafeName = this.cafes.find((cafe) => cafe.token === qr.cafe.token);
          }
          if (qr.takeout !== null) {
            takeoutName = this.takeouts.find((takeout) => takeout.token === qr.takeout.token);
          }
          if (qr.station !== null && qr.cafe !== null && qr.takeout !== null) {
            qrArray.push({
              stationName: stationInfo.nameExpFullRu,
              cafeName: cafeName.nameRu,
              takeoutName: takeoutName.nameFullRu,
              link: `${this.url()}/?qr=${qr.token}`,
              token: qr.token
            });
          }
          if (qr.station !== null && qr.cafe !== null && qr.takeout === null) {
            qrArray.push({
              stationName: stationInfo.nameExpFullRu,
              cafeName: cafeName.nameRu,
              takeoutName: 'Не установлено',
              link: `${this.url()}/?qr=${qr.token}`,
              token: qr.token
            });
          }
          if (qr.station !== null && qr.cafe === null && qr.takeout !== null) {
            qrArray.push({
              stationName: stationInfo.nameExpFullRu,
              cafeName: 'Не установлено',
              takeoutName: takeoutName.nameFullRu,
              link: `${this.url()}/?qr=${qr.token}`,
              token: qr.token
            });
          }
          if (qr.station !== null && qr.cafe === null && qr.takeout === null) {
            qrArray.push({
              stationName: stationInfo.nameExpFullRu,
              cafeName: 'Не установлено',
              takeoutName: 'Не установлено',
              link: `${this.url()}/?qr=${qr.token}`,
              token: qr.token
            });
          }
        });
      }
      return qrArray;
    },
    ...mapGetters('qr', ['qrListData']),
    ...mapGetters('qr', ['dataForCreateQr']),
    ...mapGetters('qr', ['qrListResult']),
    ...mapGetters('qr', ['deleteQrData']),
    ...mapGetters('qr', ['createQrResult']),
    ...mapGetters('qr', ['deleteQrResult']),
    ...mapGetters('qr', ['createQrErrorMessage']),
    ...mapGetters('qr', ['deleteQrErrorMessage']),

    ...mapGetters('admin', ['stationsList']),
    ...mapGetters('admin', ['stationsListResult']),

    ...mapGetters('admin', ['cafeList']),
    ...mapGetters('admin', ['cafeListResult']),

    ...mapGetters('takeouts', ['takeoutsData']),
    ...mapGetters('takeouts', ['takeoutsResult'])
  },
  methods: {
    getStation() {
      let list = [];
      if (this.createQRData !== undefined && this.createQRData !== null && this.createQRData.length >= 0) {
        this.createQRData.forEach((station) => {
          list.push({ text: station.nameExpFullRu, value: station.token });
        });
      }
      return list;
    },
    getTakeout() {
      let list = [];
      if (this.createQRData !== undefined && this.createQRData !== null && this.createQRData.length >= 0 && this.newQrData.stationToken !== null) {
        let takeoutsOfStation = this.createQRData.find((station) => station.token === this.newQrData.stationToken);
        if (takeoutsOfStation !== undefined) {
          takeoutsOfStation.takeouts.forEach((takeout) => {
            list.push({ text: takeout.nameFullRu, value: takeout.token });
          });
        }
      }
      return list;
    },
    getCafe() {
      let list = [];
      if (this.createQRData !== undefined && this.createQRData !== null && this.createQRData.length >= 0 && this.newQrData.stationToken !== null) {
        if (this.newQrData.takeoutToken !== null) {
          let cafesOfTakeout = null;
          let takeoutsOfStation = this.createQRData.find((station) => station.token === this.newQrData.stationToken);
          if (takeoutsOfStation !== undefined) {
            cafesOfTakeout = takeoutsOfStation.takeouts.find((takeout) => takeout.token === this.newQrData.takeoutToken);
          } else {
            this.newQrData.takeoutToken = null;
          }
          if (cafesOfTakeout !== undefined && cafesOfTakeout !== null) {
            cafesOfTakeout.cafes.forEach((cafe) => {
              list.push({ text: cafe.nameRu, value: cafe.token });
            });
          } else {
            this.newQrData.cafeToken = null;
            this.newQrData.takeoutToken = null;
          }
        } else {
          let cafeOfStation = this.createQRData.find((station) => station.token === this.newQrData.stationToken);
          if (cafeOfStation !== undefined && cafeOfStation !== null) {
            cafeOfStation.cafes.forEach((cafe) => {
              list.push({ text: cafe.nameRu, value: cafe.token });
            });
          } else {
            this.newQrData.takeoutToken = null;
            this.newQrData.cafeToken = null;
          }
        }
      }
      return list;
    },
    clearTakeoutAndCafe() {
      this.newQrData.cafeToken = null;
      this.newQrData.takeoutToken = null;
    },
    clearCafe() {
      this.newQrData.cafeToken = null;
    },
    createQrObject() {
      let qrArray = [];
      this.qrs.forEach((qr) => {
        let stationInfo, cafeName, takeoutName;
        if (qr.station !== null) {
          stationInfo = this.stations.find((station) => station.token === qr.station.token);
        }
        if (qr.cafe !== null) {
          cafeName = this.cafes.find((cafe) => cafe.token === qr.cafe.token);
        }
        if (qr.takeout !== null) {
          takeoutName = this.takeouts.find((takeout) => takeout.token === qr.takeout.token);
        }
        if (qr.station !== null && qr.cafe !== null && qr.takeout !== null) {
          qrArray.push({
            stationName: stationInfo.nameExpFullRu,
            cafeName: cafeName.nameRu,
            takeoutName: takeoutName.nameFullRu,
            link: `${this.url()}?qr=${qr.token}`,
            token: qr.token
          });
        }
        if (qr.station !== null && qr.cafe !== null && qr.takeout === null) {
          qrArray.push({
            stationName: stationInfo.nameExpFullRu,
            cafeName: cafeName.nameRu,
            takeoutName: 'Не установлено',
            link: `${this.url()}?qr=${qr.token}`,
            token: qr.token
          });
        }
        if (qr.station !== null && qr.cafe === null && qr.takeout !== null) {
          qrArray.push({
            stationName: stationInfo.nameExpFullRu,
            cafeName: 'Не установлено',
            takeoutName: takeoutName.nameFullRu,
            link: `${this.url()}?qr=${qr.token}`,
            token: qr.token
          });
        }
        if (qr.station !== null && qr.cafe === null && qr.takeout === null) {
          qrArray.push({
            stationName: stationInfo.nameExpFullRu,
            cafeName: 'Не установлено',
            takeoutName: 'Не установлено',
            link: `${this.url()}?qr=${qr.token}`,
            token: qr.token
          });
        }
      });
      this.qrList = JSON.parse(JSON.stringify(qrArray));
    },
    getQrList() {
      this.fetchQrList();
    },
    getCafeList() {
      this.getAllCafesList();
    },
    getStationsList() {
      this.fetchStationsList();
    },
    getTakeoutsList() {
      this.fetchTakeoutsList();
    },
    url() {
      return window.location.origin;
    },
    copyUrlClick(link) {
      navigator.clipboard.writeText(link.link).then(() => {
        this.showNotification('Ссылка скопирована', 'success', '2000', 'mdi-check');
      });
    },
    showNotification(message, color, timer, icon) {
      this.showSnack({
        message,
        color,
        timer,
        icon
      });
    },
    deleteQRClick(item) {
      this.deleteQr({ token: item.token })
        .then(() => {
          if (this.deleteQrResult) {
            this.getQrList();
            this.showNotification('QR-код удален', 'success', '2000', 'mdi-check');
          }
        })
        .catch(() => {
          this.showNotification(this.deleteQrErrorMessage, 'red', '5000', 'mdi-check');
        });
    },
    addNewQrClick() {
      let obj = {};
      if (this.newQrData.stationToken !== null) obj.stationToken = this.newQrData.stationToken;
      if (this.newQrData.cafeToken !== null) obj.cafeToken = this.newQrData.cafeToken;
      if (this.newQrData.takeoutToken !== null) obj.takeoutToken = this.newQrData.takeoutToken;
      this.createQr(obj)
        .then(() => {
          if (this.createQrResult) {
            this.getQrList();
            this.closeDialog();
            this.showNotification('QR-код создан', 'success', '2000', 'mdi-check');
          }
        })
        .catch(() => {
          this.showNotification(this.createQrErrorMessage, 'red', '5000', 'mdi-check');
        });
    },
    closeDialog() {
      this.createQrDialog = false;
      this.newQrData.stationToken = null;
      this.newQrData.cafeToken = null;
      this.newQrData.takeoutToken = null;
    },
    ...mapActions('qr', ['deleteQr']),
    ...mapActions('qr', ['createQr']),

    ...mapActions('admin', ['fetchStationsList']),
    ...mapActions('admin', ['getAllCafesList']),
    ...mapActions('takeouts', ['fetchPermissionTakeoutsList']),
    ...mapActions('qr', ['fetchQrList']),
    ...mapActions('notification', ['showSnack'])
  },
  mounted() {
    this.getQrList();
    this.getCafeList();
    this.getStationsList();
    this.fetchPermissionTakeoutsList();
  },
  watch: {
    qrObj(newVal) {
      if (newVal !== null) {
        this.qrList = newVal;
      }
    }
  }
};
</script>
<style scoped>
.notification {
  position: fixed;
  bottom: 5px;
  right: 5px;
}
</style>
